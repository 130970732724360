<template>
  <div class="relative" :class="{ [custom]: custom }">
    <label v-if="!!label || $slots.label" :for="uid" class="text-13 mb-4 block text-gray-700 dark:text-gray-100">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="relative">
      <field
        :id="uid"
        v-model="model"
        class="rounded-4 text-13 flex h-full w-full appearance-none items-center border border-gray-200 bg-white px-8 py-10 text-black outline-none focus-visible:!border-blue-500 dark:border-gray-600 dark:bg-black dark:text-white"
        :class="{
          '!border-gray-200  !bg-gray-50 !text-gray-300 dark:!border-gray-600 dark:!bg-gray-800  dark:!text-gray-500':
            disabled,
          '!text-gray-300 dark:!text-gray-500': model === '' && !!placeholder,
          '!border-red-500': !!hasErrors,
          '!border-[--bb-select__border] !bg-[--bb-select__bg] !text-[--bb-select__text] dark:!border-[--bb-select__border--dark] dark:!bg-[--bb-select__bg--dark] dark:!text-[--bb-select__text--dark]':
            !!custom,
        }"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        as="select"
        :autocomplete="autocomplete"
      >
        <option v-if="placeholder" value="" disabled :selected="model === ''" v-text="placeholder" />
        <option
          v-for="(option, i) in items"
          :key="i"
          :value="option.value"
          :selected="option.value === model"
          v-text="option.label"
        />
      </field>
      <local-font-awesome-icon
        class="text-13 absolute right-10 top-1/2 -translate-y-1/2 text-gray-400"
        :class="{ '!text-[--bb-select__arrow] dark:!text-[--bb-select__arrow--dark]': !!custom }"
        :icon="faChevronDown"
      />
    </div>
    <error-message class="text-11 absolute left-0 top-full pt-4 text-red-500" :name="name" />
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
// rename to prevent name collision with global registered component
import { FontAwesomeIcon as LocalFontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Field, ErrorMessage, useField } from 'vee-validate';
import { computed, useId } from 'vue';

const {
  name,
  options,
  placeholder = '',
  label = '',
  autocomplete = 'off',

  custom = '',
} = defineProps<{
  // Using HTMLInputElement['autocomplete'] results in 'Expression produces a union type that is too complex to represent'
  autocomplete?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;

  options: Array<T | { label: string; value: T }>;

  custom?: string;
}>();

const model = defineModel<T>();

const uid = useId();

const hasErrors = useField(name).errorMessage;

const items = computed(() => {
  return options.map(item => {
    if (typeof item === 'object') return item;
    return { label: item, value: item };
  });
});
</script>
